import { GameInfo as Eikoo } from "./eikoo/Game.ts";
import { GameInfo as CoolRPG } from "./coolrpg/Game.ts";
import { GameInfo as SpritePacker } from "./sprite-packer/Game.ts";
import { GameInfo as BasketballGame } from "./basketball/Game.ts";
import { GameInfo as MidiGridiGame } from "./midi-gridi/Game.ts";
import BoxesGame from "./boxes/mod.ts";
import { BaseGame } from "~/games/deps.ts";

export const games = {
  "eikoo": Eikoo,
  "coolrpg": CoolRPG,
  "boxes": BoxesGame,
  "sprite-packer": SpritePacker,
  "basketball": BasketballGame,
  "midi-gridi": MidiGridiGame,
};

export const gameList = Object.keys(games);
export type Games = typeof games;
export type Game = keyof typeof games;
export type GameInstance = BaseGame; //|InstanceType<typeof games[Game]["class"]>;
export type GameProps = Omit<
  ConstructorParameters<typeof games[Game]["class"]>[0],
  "id"
>;
